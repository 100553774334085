import Api from '@api';

import common from './blocks/common'
import tag from './blocks/tag'

import { formConfig } from './config/formConfig'

export default {
  name: 'consignmentDetail',
  components: {
    common,
    tag
  },
  data() {
    const thead = formConfig(this).thead;
    return {
      commonData: {},
      thead
    };
  },
  computed:{
    injectData(){
      return this.$route.query || {}
    }
  },
  activated() {
    this.getData();
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let { status, data } = await Api.GetConsignmentByVmiId({
        CONSIGNMENT: this.injectData.CONSIGNMENT,
        SITE_ID: this.injectData.SITE_ID
      });
      if (Number(status) === 200) {

        let result = JSON.parse(JSON.stringify(data));
        this.$refs.common.$refs.common.formData = result;

        this.thead
          .filter(item => item !== "THead")
          .forEach(item => {
            this.$refs.tag.$refs[item][0].formData = result
          })
      }
    },
    handleConfirm() {
      this.$emit('close')
    },
    handleClose() {
      this.$emit('close')
    }
  }
}