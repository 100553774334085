export const tab_en = {
  "THead": "THead",
  "mqs_consignment_tab1": "mqs_consignment_tab1",
  "mqs_consignment_tab2": "mqs_consignment_tab2",
  "mqs_consignment_tab0": "mqs_consignment_tab0",
  "mqs_cons_user_def_tab0": "mqs_cons_user_def_tab0"
};

export const tab_zh = {
  "THead": "表头",
  "mqs_consignment_tab1": "仓库",
  "mqs_consignment_tab2": "客户",
  "mqs_consignment_tab0": "综合",
  "mqs_cons_user_def_tab0": "用户自定义的_设置1"
} 